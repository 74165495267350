import { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./carousel.scss"
import img_1 from "../../assets/1.jpg"
import img_2 from "../../assets/2.jpg"
import img_3 from "../../assets/3.png"
import Typing from "./Typing";
const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const images = [
    img_1,img_2,img_3
  ];
  const content = [
    {
      heading:"Eten Corporation",
      desc:"Eten Corporation is a leading distributor in the meat industry, operating throughout North America with an extensive range of meats and a focus on high quality products.",
    },
    {
      heading:"Quality Meats from Trusted Sources",
      desc:"We specialize in variety of meats such as beef, chicken and turkey with a strong emphasis on quality assurance and customer satisfaction. Our meats are sourced from reputable producers throughout Canada and the US.",
    },
    {
      heading:"Halal Certified Products",
      desc:"We specialize in the distribution of halal products. We work with a number of reputable and authenticated halal certification agency’s to ensure that all products meet the necessary requirements and principles to be deemed halal.",
    },
  ]
  const slideVariants = {
    hiddenRight: {
      x: "100%",
      opacity: 0,
    },
    hiddenLeft: {
      x: "-100%",
      opacity: 0,
    },
    visible: {
      x: "0",
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.5,
      },
    },
  };
  const slidersVariants = {
    hover: {
      scale: 1.2,
      backgroundColor: "#ff00008e",
    },
  };
  const dotsVariants = {
    initial: {
      y: 0,
    },
    animate: {
      y: -10,
      scale: 1.2,
      transition: { type: "spring", stiffness: 1000, damping: "10" },
    },
    hover: {
      scale: 1.1,
      transition: { duration: 0.2 },
    },
  };

  const handleNext = () => {
    setDirection("right");
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === images.length ? 0 : prevIndex + 1
    );
  };

  const handlePrevious = () => {
    setDirection("left");

    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleDotClick = (index) => {
    setDirection(index > currentIndex ? "right" : "left");
    setCurrentIndex(index);
  };

  const textVariants = {
    initial: {
      x: -100,
      opacity: 0,
    },
    animate: {
      x: 0,
      opacity: 1,
      transition: {
        duration: 1,
        staggerChildren: 0.1,
      },
    },
    scrollButton: {
      opacity: 0,
      y: 10,
      transition: {
        duration: 2,
        repeat: Infinity,
      },
    },
  };


    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 10000);
  
      // Clear the timeout when the component unmounts or when the user interacts with the slider
      return () => clearTimeout(timeoutId);
    }, [currentIndex, images.length]);

  return (
    <div className="carousel">
      <div className="cap-img">      
        <div className="carousel-images">
        {/* <AnimatePresence> */}
          <motion.img
            key={currentIndex}
            src={images[currentIndex]}
            initial={direction === "right" ? "hiddenRight" : "hiddenLeft"}
            animate="visible"
            exit="exit"
            variants={slideVariants}
          />
        {/* </AnimatePresence> */}
        {/* <div className="slide_direction">
          <motion.div
            variants={slidersVariants}
            whileHover="hover"
            className="left"
            onClick={handlePrevious}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="M400 976 0 576l400-400 56 57-343 343 343 343-56 57Z" />
            </svg>
          </motion.div>
          <motion.div
            variants={slidersVariants}
            whileHover="hover"
            className="right"
            onClick={handleNext}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20"
              viewBox="0 96 960 960"
              width="20"
            >
              <path d="m304 974-56-57 343-343-343-343 56-57 400 400-400 400Z" />
            </svg>
          </motion.div>
        </div> */}
      </div>
      {/* <h2>{currentIndex} {content[currentIndex]} </h2> */}
      <motion.div variants={textVariants} key={currentIndex} className="content">
        {/* <motion.h2 variants={textVariants} key={currentIndex}>{content[currentIndex]?.heading} </motion.h2> */}
        <h2> {content[currentIndex]?.heading}</h2>
        <span className="span-1"></span>
        <span className="span-2"></span>
        <p>{content[currentIndex]?.desc} </p>
      </motion.div>
      
       {/* <Typing text={content[currentIndex]} key={currentIndex}/> */}
      </div>

      <div className="carousel-indicator">
        {images.map((_, index) => (
          <motion.div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => handleDotClick(index)}
            initial="initial"
            animate={currentIndex === index ? "animate" : ""}
            whileHover="hover"
            variants={dotsVariants}
          ></motion.div>
        ))}
      </div>
    </div>
  );
};
export default Carousel;