import { useEffect, useRef, useState } from "react";
import Test from "./Test";
import "./app.scss";
import About from "./components/about/About";
import Animals from "./components/animals/Animals";
import Contact from "./components/contact/Contact";
import ContactPage from "./components/contact/ContactPage";
import Cursor from "./components/cursor/Cursor";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import Parallax from "./components/parallax/Parallax";
import Portfolio from "./components/portfolio/Portfolio";
import Services from "./components/services/Services";
import { useInView } from "framer-motion";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { motion } from "framer-motion";
const App = () => {
  
  const location = useLocation();
  // console.log(location)

  const [data, setData] = useState(null);

  const bottomRef = useRef(null);

  const scrollToBottom = (val) => {
    if (bottomRef?.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
      setData(`Please provide the quote for ${val} along with all the cuts`);
    }
  };

  const topRef = useRef(null);

  const scrollToUp = (val) => {
    if (topRef?.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
      // setData(`Please provide the quote for ${val} along with all the cuts`);
    }
  };

  useEffect(() => {
    setData(null);
    console.log(location);
    if (topRef?.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
      // setData(`Please provide the quote for ${val} along with all the cuts`);
    }
  }, [location.pathname]);

  return (
    <div ref={topRef}>
      <Toaster position="top-center" reverseOrder={false} />
      <Navbar />
      <Switch>
        <Route path="/" exact component={Hero} />
        <Route path="/about" component={About} />
        <Route path="/product" exact component={Services} />
        {/* <Route path="/contact" component={ContactPage} /> */}
        <Route
          path="/contact"
          render={(props) => (
            <ContactPage {...props} scrollToBottom={scrollToBottom} />
          )}
        />
        {/* <Route path="/product/:animal" component={Animals} /> */}
        {/* Add a default route for any path */}
        <Route component={Hero} />
      </Switch>
      <span ref={bottomRef}>
        <Contact data={data} />
      </span>
    </div>
  );
};

export default App;
