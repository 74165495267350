import React from 'react'
import './pitch.scss'
import { listData } from '../constant'
import { useHistory } from 'react-router-dom'

const OurOffers = ({isC=false,scrollToBottom=()=>{}}) => {
    const history = useHistory()

    const routeIt = () => {
        history.push('/product')
    }
  return (
    <div className={isC ? "ouroffer" : 'pitch-box black'}>
        <div className="over"></div>
        <div className="ptch-content">
            <div className=" offer-text text-wrapper">
                <h1>{isC ? "Contact Us" : "Our Offerings"}</h1>
                {isC ? <span className='pitch-text'>
                Contact us regarding our offering of the finest Beef, Chicken, and Turkey, available in a variety of cuts.
                </span>:
                <span className='pitch-text'>
                    At <strong>Eten Corporation</strong>, we offer the finest in Beef, Chicken and Turkey that are ready and available in a variety of cuts. 
                </span>}
                <div className='dot-box w-box'>
                    <span className='me-dot w'></span>
                    <span className='me-dot w'></span>
                    <span className='me-dot w'></span>
                </div>
                <div className='category'>
                    {listData?.map(item=>(                        
                        <div className={`${item?.name?.toLowerCase()} animal`} onClick={()=>scrollToBottom(item?.name)}>
                            <div className='img-div'>
                                <img src={item?.img} />
                                <h1>{item?.name}</h1>
                            </div>
                        </div>
                    ))}
                </div>
                {!isC && <button className="rounded-button" onClick={routeIt}>See Our Product Catalogue</button>
}            </div>
        </div>
    </div>
  )
}

export default OurOffers