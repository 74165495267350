import { useEffect, useRef, useState } from "react";
import "./contact.scss";
import { motion, useInView } from "framer-motion";
import emailjs from "@emailjs/browser";
import { CircularProgress } from "@mui/material";
import toast, { ToastBar } from "react-hot-toast";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
const variants = {
  initial: {
    x: 0,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Contact = ({modal=false,desc="",data=null}) => {
  const ref = useRef();
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [animationTriggered, setAnimationTriggered] = useState(false);


  // const isInView = useInView(ref, { margin: "-100px" });
  const isInView = useInView(ref, { once: true,margin: "-100px" });

  console.log(isInView,ref,animationTriggered)

  useEffect(() => {
    // setTimeout(()=>{
    //   setAnimationTriggered(true)
    // },5000)
    // console.log('timeout will wxecute after 3 sec')
    return ()=>{
      setAnimationTriggered(true)
    }
  }, []);



  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)

    emailjs
      .sendForm(
        "service_0zux2j8",
        "template_ut2i5nk",
        formRef.current,
        "2p2xNRMc5EToZpbe3"
      )
      .then(
        (result) => {
          setSuccess(true);
          toast.success('Email sent succesfully. We"ll get back to you.')
          setLoading(false)

        },
        (error) => {
          // setError(true);
          toast.error('Something Went wrong')
          setLoading(false)
        }
      );
  };

  return (
    modal ? 
    <>
  <div className="formContainerT">
    <form onSubmit={sendEmail} ref={formRef}>
      <input type="text" required placeholder="Name" name="name" />
      <input type="email" required placeholder="Email" name="email" />
      <textarea rows={8} value={`Please provide the quote for ${desc} along with all the cuts`} placeholder="Message" name="message" />
      <button type="submit">Submit</button>
      {/* {error && "Error"}
      {success && "Success"} */}
    </form>
  </div>
  </>
    :
    <>

    <motion.div
      // ref={ref}
      className="contact"
      // variants={variants}
      // initial="initial"
      animate={"animate"}
      // animate={isInView ? "animate": false}
    >
      <motion.div className="textContainer" variants={variants}>
        <motion.span 
          // initial={{ opacity: 0 }}
          // whileInView={{ opacity: 1 }}
          // transition={{ delay: 3, duration:  1 }}
        >
          <motion.h1 variants={variants}><ContactPageIcon fontSize="large"/> Contact Us</motion.h1>
          <motion.div className="item" variants={variants}>
            <h2><EmailIcon fontSize="medium"/> Mail</h2>
            <span>info@etencorp.com</span>
          </motion.div>
          <motion.div className="item" variants={variants}>
            <h2> <CallIcon fontSize="medium"/> Phone</h2>
            <span>+1 437-980-6750</span>
          </motion.div>
        </motion.span>
      </motion.div>
      
      <motion.div className="formContainer">
        <motion.form
          ref={formRef}
          onSubmit={sendEmail}
          // initial={{ opacity: 0 }}
          // whileInView={{ opacity: 1 }}
          // transition={{ delay: 4, duration: 1 }}
        >
          <input type="text" required placeholder="Name" name="name" />
          <input type="email" required placeholder="Email" name="email" />
          <input type="number"  placeholder="Phone No" name="contact" />
          <textarea rows={8} defaultValue={data} placeholder={data?? "Enter Your Message"} name="message" />
          <button>Submit {loading ? <CircularProgress/>:<></>}</button>
          {/* {error && "Error"} */}
          {/* {success && "Success"} */}
        </motion.form>
      </motion.div>
    </motion.div>
    <footer>
      <p>&copy; {new Date().getFullYear()} All rights reserved | Eten Corporation</p>
    </footer>
    </>
  );
};

export default Contact;
