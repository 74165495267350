import Test from "../../Test";
import Carousel from "../crousel/Carousel";
import OurOffers from "./OurOffers";
import {Pitch} from "./Pitch";
import YourComponent from "./Test";
import "./hero.scss";
import { motion } from "framer-motion";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
  scrollButton: {
    opacity: 0,
    y: 10,
    transition: {
      duration: 2,
      repeat: Infinity,
    },
  },
};
const sliderVariants = {
  initial: {
    x: 0,
  },
  animate: {
    x: "-160%",
    transition: {
      repeat: Infinity,
      repeatType:"mirror",
      duration: 12,
    },
  },
};

const Hero = () => {
  return (
    <>
      <div className="hero ">
        <div className="wrapper">
          <motion.div
            className="textContainer"
            variants={textVariants}
            initial="initial"
            animate="animate"
          >
            {/* <motion.h1 variants={textVariants}>
            Eten Corporation
            </motion.h1>
            <motion.h2 variants={textVariants}>Eten Corporation</motion.h2>

            <motion.div variants={textVariants} className="buttons">
              <motion.button variants={textVariants}>
                See the Latest Works
              </motion.button>
              <motion.button variants={textVariants}>Contact Me</motion.button>
            </motion.div> */}
            {/* <motion.img
              variants={textVariants}
              animate="scrollButton"
              src="../../assets/scroll.png"
              alt=""
            /> */}
            <Carousel/>
          </motion.div>
        </div>
        <motion.div
          className="slidingTextContainer"
          variants={sliderVariants}
          initial="initial"
          animate="animate"
          >
          QUALITY. HALAL. MEAT.
        </motion.div>
        {/* <div className="imageContainer">
          <img src="../../assets/hero.png" alt="" />
        </div> */}
      </div>
      <Pitch/>
      <OurOffers/>
      {/* <YourComponent/> */}
    </>
  );
};

export default Hero;
