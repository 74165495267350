import { useRef, useState } from "react";
import "./services.scss";
import { motion, useInView } from "framer-motion";

import TransitionsModal from "./Modal";
import { listData } from "../constant";
const variants = {
  initial: {
    x: +500,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Services = () => {
  const ref = useRef();

  const isInView = useInView(ref, { margin: "-100px" });

  const [isModal,setModal] = useState(null)


  const viewMore = (cta) => {
    setModal(cta)
  }

  return (
    <motion.div
      className="services"
      variants={variants}
      initial="initial"
      // animate="animate"
      // whileInView="animate"
      ref={ref}
      animate={"animate"}
    >
      {/* <motion.div className="textContainer" variants={variants}>
        <p>
          We meet your needs by providing
          <br /> Quality. Meat.
        </p>
        <hr />
      </motion.div> */}
      <motion.div className="titleContainer" variants={variants}>
      <div className="title">
          <h1>Our
            <motion.b whileHover={{color:"orange"}}> Food </motion.b>
            Service Products
          </h1>
          <p>
          At <motion.b whileHover={{color:"orange"}}>Eten Corporation </motion.b>, we offer the finest in <motion.b whileHover={{color:"orange"}}> Beef, </motion.b> <motion.b whileHover={{color:"orange"}}> Chicken </motion.b>  and <motion.b whileHover={{color:"orange"}}>Turkey </motion.b> that are ready and available in a variety of cuts. We take pride in our selections, as we understand the importance of quality and taste.
          </p>
      </div>
      </motion.div>
      <motion.div className="listContainer" variants={variants}>
        {listData.map((item)=>(
            <motion.div
            className="box"
            whileHover={{ background: "lightgray", color: "black" }}
            key={item?.name}
          >
            <img src={item?.img} alt="chicken"/>
            <div className="box-2">
              <h2>{item?.name}</h2>
              <p className="desc" dangerouslySetInnerHTML={{__html:item?.desc}}/>
              <button onClick={()=>viewMore(item?.cta)}>View More</button>
            </div>
          </motion.div>
        ))}

      </motion.div>
      {isModal && <TransitionsModal open={isModal} setOpen={setModal}/>}
    </motion.div>
  );
};

export default Services;
