import { useRef } from "react";
import "./../services/services.scss";
import { motion, useInView } from "framer-motion";
import logo from '../../assets/logo.svg'
import aboutUs from '../../assets/about us.png'
import TabSection from "./TabSection";
const variants = {
  initial: {
    x: -500,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};

const About = () => {
  const ref = useRef();

  const isInView = useInView(ref, { margin: "-100px" });

  return (
    <motion.div
      className="services"
      variants={variants}
      initial="initial"
      animate="animate"
      // whileInView="animate"
      ref={ref}
      // animate={isInView ? "animate":false}
    >
      {/* <motion.div className="textContainer" variants={variants}>
        <p>
          We meet your needs by providing
          <br /> Quality. Meat.
        </p>
        <hr />
      </motion.div> */}
      <motion.div className="titleContainer" variants={variants}>
        <div className="title">
          {/* <img src="/people.webp" alt="" /> */}
          <h1>
            Our
            <motion.b whileHover={{color:"orange"}}> Introduction</motion.b>
          </h1>
        </div>
        <div className="intro-box">
            <div className="sub-div">
            
            <img src={aboutUs} logo="" className="about-img"/>
            {/* <img src={logo} alt="logo" className="my-img"/> */}
            <span className="big-span">
                <span className="sub-span">
                <motion.b whileHover={{color:"orange"}}>Welcome to</motion.b>
                <img src={logo} alt="logo" className="my-img"/>
                </span>
                Your premier partner in the meat industry. With years of expertise and a strong commitment to quality, we have built a reputation as a trusted supplier for businesses in the industry. We take immense pride in our commitment to sourcing only the finest meats from reputable farms. We work closely with producers who share our values of sustainable and ethical practices, ensuring that every cut we offer meets the highest standards of quality and flavor.
                <img src={aboutUs} logo="" className="about-img-2"/>
                </span>
            </div>
        </div>
        <TabSection/>

        {/* <div className="title">
          <h1>
            <motion.b whileHover={{color:"orange"}}>For Your</motion.b> Needs.
          </h1>
          <button>Our Offerings?</button>
        </div> */}
      </motion.div>

    </motion.div>
  );
};

export default About;
