import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import logo from "../../../assets/logo.svg"

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};
const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

const Links = ({setOpenWin}) => {
  const history = useHistory()
  const items = ["Home", "About", "Product", "Contact"];
  const routeIt = (a) => {
    history.push(`/${a.toLowerCase()}`)
    setOpenWin(prev=>!prev)
  }
  return (
    <motion.div className="links" variants={variants}>
                    <motion.span
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="logo-span"
        >
          <img className="logo" src={logo} alt="logo"/>
        </motion.span>
      {items.map((item) => (
        <motion.a
          onClick={()=>routeIt(item)}
          key={item}
          variants={itemVariants}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
        >
          {item}
        </motion.a>
      ))}

    </motion.div>
  );
};

export default Links;
