import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import chicken from "../../assets/chicken.svg"
import cow from "../../assets/cow.svg"
import turkey from "../../assets/turkey.svg"
import "./modal.scss";
import ModalTab from "./ModalTab";
import Contact from "../contact/Contact";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width:"675px"
};

const data = {
  'c': [
    {
      'heading_0': [
        "White Meat",
        "Ground",
        "MDM",
        "Breast (Bone-in)",
        "Breast (Boneless, Skinless)",
        "Tenders",
      ]},
      {"heading_1": [
        "Dark Meat",
        "Ground",
        "MDM",
        "Thigh (Bone-in)",
        "Breast (Boneless, Skinless)",
        "Leg Meat",
        "Drum",
      ]},
    //   {"heading_2": [
    //     "Fowl",
    //     "Tenders",
    //     "White",
    //     "Rock",
    //     "Leg",
    //     "Horn",
    //     "Ground",
    //     "Leg Meat",
    //     "Breast Meat",
    //     "Whole Bird",
    //   ],
    // },
  ],
  'b':[ { "heading_0": ["Trim","50s", "60s", "65s", "85s", "80s", "95s"] }],
  't': [{
    "heading_0": [
        "White Meat",
      "Breast",
      "Ground",
      "Tenders",
      "Bone-in Tom, Hen, Breeder",
      "Boneless, Skinless Tom, Hen, Breeder",
    ]},
    {"heading_1": [
        "Dark Meat",
      "Ground",
      "Drum",
      "Thigh (Bone-in)",
      "Thigh (Boneless, Skinless)",
    ],
  }]
};

const heading = {
  c:{name:"Chicken",img:chicken},
  b:{name:"Beef",img:cow},
  t:{name:"Turkey",img:turkey}
}
export default function TransitionsModal({ open = null, setOpen = () => {} }) {
    console.log(data[open])
  //   const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  const [show,setShow] = useState(false)

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
         <Box sx={style} className="modal-win">
            {!show? <span className="img-c">
            <RowAndColumnSpacing open={open} setShow={setShow} handleClose={handleClose}/>
          </span>:
          <>
              <span className="header">
              <button className="close-btn" onClick={handleClose}>x</button>
                <img src={heading[open]?.img} alt="" className="img-1" />
                <h1 className="he1">{heading[open]?.name} - Get a Quote</h1>
                <img src={heading[open]?.img} alt="" className="img-2"/>
              </span>
          <Contact modal={true} desc={heading[open]?.name}/>
          <button className="mdl-btn" onClick={()=>setShow(!show)}>Go Back</button>
          </>
          }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function RowAndColumnSpacing({open=null,setShow,handleClose}) {
  const showContact = () => {
    setShow(prevState=>!prevState)
  }
  return (
    // <Box sx={style} className="modal-win">
//     <ThemeProvider
//     theme={createTheme({
//       breakpoints: {
//         values: {
//           laptop: 1024,
//           tablet: 640,
//           mobile: 0,
//           desktop: 1280,
//         },
//       },
//     })}
//   >
<>
    <button className="close-btn" onClick={handleClose}>x</button>
    <span className="header">

      <img src={heading[open]?.img} alt="" className="img-1" />
      <h1 className="he1">{heading[open]?.name}</h1>
      <img src={heading[open]?.img} alt="" className="img-2"/>
    </span>
      {/* <Grid  container rowSpacing={1} 
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      >
        {data?.[open]?.map((item,key)=>(<Grid className="p-0" xs={12/data?.[open]?.length}>
                <Box className="menu-box">
                    <h2 className="he2">{item?.[`heading_${key}`]?.[0]}</h2>
                    <ul>
                    {item?.[`heading_${key}`]?.slice(1)?.map((i)=>(
                        <li>{i}</li>
                    ))}
                    </ul>
                </Box>
        </Grid> ))}
        </Grid> */}
        <ModalTab tabs={data?.[open]}></ModalTab>

        <button className="mdl-btn" onClick={showContact}>Contact us to get a quote</button>
{/* 
        <Grid xs={4}>
          <Item>2</Item>
        </Grid>
        <Grid xs={4}>
          <Item>3</Item>
        </Grid> */}

      </>
    //   </ThemeProvider>
    // </Box>
  );
}