import React from 'react'
import Contact from './Contact'
import OurOffers from '../hero/OurOffers'
import { motion } from 'framer-motion'

const ContactPage = ({scrollToBottom}) => {
    const textVariants = {
        initial: {
          x: 500,
          opacity: 0,
        },
        animate: {
          x: 0,
          opacity: 1,
          transition: {
            duration: 1,
            staggerChildren: 0.1,
          },
        },
        scrollButton: {
          opacity: 0,
          y: 10,
          transition: {
            duration: 2,
            repeat: Infinity,
          },
        },
      };
  return (
    <motion.div             
    className="textContainer"
    variants={textVariants}
    initial="initial"
    animate="animate">
        <OurOffers isC={true} scrollToBottom={scrollToBottom}/>
        {/* <Contact/> */}
    </motion.div>
  )
}

export default ContactPage