import React from "react";
import "./pitch.scss";
import { useHistory } from "react-router-dom";

export const Pitch = () => {
  const history = useHistory()
  return (
    <div className="pitch-box meat">
      <div className="over"></div>
      <div className="ptch-content">
        <div className="text-wrapper">
          <h1>
            Elevate your culinary experience with{" "}
            <strong>Eten Corporation</strong>
          </h1>
          <div className="dot-box">
            <span className="me-dot"></span>
            <span className="me-dot"></span>
            <span className="me-dot"></span>
          </div>
          <span className="pitch-text">
            Welcome to <strong>Eten Corporation</strong>, your premier partner
            in the meat industry. With years of expertise, we've earned trust as
            a supplier committed to quality. Operating in Canada and the United
            States, we specialize in distributing top-quality beef, chicken, and
            turkey, offering halal and non-halal options. Our extensive network
            of trusted farmers ensures a diverse range of premium meats sourced
            exclusively from North American farms.
            <br />
            <br />
            At <strong>Eten Corporation</strong>, we recognize the industry's
            unique needs. We've tailored our operations to deliver the highest
            quality meats, reliable service, and competitive pricing. Our
            commitment to quality assurance is unparalleled — every batch
            undergoes meticulous inspection for freshness, flavor, and food
            safety.
            <br />
            <br />
            Choose <strong>Eten Corporation</strong> for personalized, reliable
            service, valuable industry insights, customized orders, and
            responsive support.
            <br />
            <br /> For any inquiries, feel free to{" "}
            <b className="cus" onClick={() => {history.push('/contact')}}>
              contact us
            </b>
            .
            {/* Elevate your expectations with <strong>Eten Corporation</strong>. */}
          </span>
        </div>
      </div>
    </div>
  );
};

// export default Pitch
