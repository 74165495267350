import chicken from "../assets/chicken.svg"
import cow from "../assets/cow.svg"
import turkey from "../assets/turkey.svg"
export const listData = [
    {
      name:"Chicken",
      img:chicken,
      desc:"At <b>Eten Corporation</b>, we recognize that <b>Chicken</b> is the most common type of protein consumed worldwide. We take pride in being your go-to supplier for high-quality chicken products.",
      cta:"c"
    },
    {
      name:"Beef",
      img:cow,
      desc:"At <b>Eten Coporation</b>, we take pride in being your premier partner in the meat industry. We specialize in providing a wide range of <b>Beef</b> cuts to meet your specific needs:",
      cta:"b"
    },
    {
      name:"Trukey",
      img:turkey,
      desc:"<b>Turkey</b>, much like chicken, is a renowned poultry product cherished worldwide. It holds a special place in our hearts and tables, frequently gracing festive occasions such as Christmas and Thanksgiving.",
      cta:"t"
    }
  ]
