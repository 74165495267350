
import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './tab.scss'
import aboutus from '../../assets/about_us.png'
import aboutus2 from '../../assets/aboutus2.png'
import aboutus3 from '../../assets/aboustus3.jpg'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabSection() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }} className="box-1">
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className='tab' >
          <Tab icon={<PersonPinIcon />} label="About Us" {...a11yProps(0)} />
          <Tab icon={<SettingsIcon />} label="Custom Strategy" {...a11yProps(1)} />
          <Tab  icon={<VerifiedUserIcon />}  label="Quality Care" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} className="desc">
        <p>
        We operate in Canada and the United States and specialize in the distribution of beef, chicken, and turkey, with halal and non-halal options available. 
        With years of expertise and a strong commitment to quality, we have built a reputation as a trusted supplier for businesses in the industry. We take immense pride in our commitment to sourcing only the finest meats from reputable farms in North America.
        </p>
        {/* <button className='o-button'>View More</button> */}
        <img src={aboutus} alt=""></img>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} className="desc">
        <p>

        We understand the unique needs and challenges in the industry, and that's why we have tailored our operations to provide you with the highest quality meats, reliable service, and competitive pricing.
        Our extensive network of trusted farmers and producers allows us to source a wide range of premium meats to meet your specific requirements.
        </p>
        <img src={aboutus2} alt=""></img>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2} className="desc">
        We take great pride in our commitment to quality assurance. Our team of experts meticulously inspects every batch of meat we receive, ensuring that it meets the strictest standards of freshness, flavor, and food safety. 
        We provide personalized and reliable service to meet your unique needs. 
        Our dedicated team is here to assist you, offering valuable industry insights, customized order solutions, and responsive support.
        <img src={aboutus3} alt=""></img>
        
      </CustomTabPanel>
    </Box>
  );
}
