import Sidebar from "../sidebar/Sidebar";
import "./navbar.scss";
import { motion } from "framer-motion";
import logo from "../../assets/logo.svg"
import {Link} from 'react-router-dom'
import { useLocation } from "react-router-dom";
import { useRef } from "react";
const Navbar = ({scrollToBottom=()=>{}}) => {

  const location = useLocation()
  return (
    <>
      <Sidebar/>
    <div  className="navbar">
      {/* Sidebar */}
      {/* <div className="wrapper"> */}
      <motion.span
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="logo-span"
        >
          <img className="logo" src={logo} alt="logo"/>
        </motion.span>

          {/* lama dev */}
          <div className="social">
          <Link to="/home" onClick={scrollToBottom}>
            <span className={location?.pathname==="/home" && "activee"}>Home</span>
          </Link>
          <Link to="/about" onClick={scrollToBottom}>
            <span className={location?.pathname==="/about" && "activee"}>About us</span>
          </Link>

          <Link to="/product" onClick={scrollToBottom}>
            <span className={location?.pathname==="/product" && "activee"}>Our Offerings</span>
          </Link>
          <Link to="/contact" onClick={scrollToBottom}>
            <span className={location?.pathname==="/contact" && "activee"}>Contact Us</span>
          </Link>
        {/* </div> */}

      </div>
    </div>
    </>
  );
};

export default Navbar;
